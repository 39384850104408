import React, {Component} from 'react';
import {Outlet} from 'react-router-dom';
import Menu from './Menu';
import Footer from './Footer';
import {api} from './../common/api';
import {cache} from './../common/cache';

export default  class Main extends Component {
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
  }
  async logout() {
    cache.clear('token');
    await api.logout();
    window.location.reload();
  }
  render() { 
   
    return (   
      <>
        <Menu />
        <main id="main">
              <header className="header">
                <nav className="navbar">
                  <div className="container-fluid">
                    <div className="navbar-holder d-flex align-items-center justify-content-between">
                      <div className="navbar-header">
                        <a href="" id="m-menu-open" className="menu-btn desktop-hide "><i className="material-icons"> menu</i></a>
                        <a href="" data-action="link-my-app" className="navbar-brand">Quản trị nội dung</a>
                      </div>
                      <ul className="nav-menu list-unstyled d-flex flex-md-row align-items-md-center">                 
                        <li className="nav-item"><a className="nav-link logout" onClick={this.logout}> 
                          <span className="d-none d-sm-inline-block pointer">Logout</span><i className="material-icons">logout</i></a></li>
                      </ul>
                    </div>
                  </div>
                </nav>
              </header>
              <div className="main-body" id="main-body">
               <Outlet />
              </div>
            </main> 
        <Footer />
      </>
     );
  }
 

}
