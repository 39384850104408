import {cache} from './cache';
const postData = async(url = '', data = {})=> {
  try{
    let dataP = {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }
    if(cache.get('token')) {
      Object.assign(dataP['headers'], {'Authorization': `Bearer ${cache.get('token')}`});
    }
    url = (cache.get('apiHost')? cache.get('apiHost'): '' ) + url;
    const response = await fetch(url,dataP );
    return await response.json();
  }catch(err){
	return [204, err.message];
  }

}
// @options = "tl|tc|tr|ml|mc|mr|bl|bc|br"
const alertInit = (title, type, options)  => {
      var tid = "gdk_alert_bbbb";
      var opt = '';
      switch(options) {
        case "tl":
          opt = 'top-0 start-0';
          break;
        case "tc":
          opt = 'top-0 start-50 translate-middle-x';
          break;
        case "tr":
          opt = "top-0 end-0";
          break;
        case "ml":
          opt = 'top-50 start-0 translate-middle-y';
          break;
        case "mc":
          opt = "top-50 start-50 translate-middle";
          break;
        case "mr":
          opt = 'top-50 end-0 translate-middle-y';
          break;
        case "bl":
          opt = 'bottom-0 start-0';
          break;
        case "bc":
          opt = 'bottom-0 start-50 translate-middle-x';
          break;
        case "br":
          opt = 'bottom-0 end-0';
          break;
        default:
          opt = 'bottom-0 end-0';
          break;
      }
      switch(type){
        case 'error':
          title = `<div class="alert alert-danger d-flex align-items-center" role="alert">
  <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill"/></svg>
  <div>${title}</div>
</div>`;
          break;
        case 'warning':
          title = `<div class="alert alert-warning d-flex align-items-center" role="alert">
  <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Warning:"><use xlink:href="#exclamation-triangle-fill"/></svg>
  <div>${title}</div>
</div>`;
          break;
        case 'success':
          title = `<div class="alert alert-success d-flex align-items-center" role="alert">
  <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-circle-fill"/></svg>
  <div>${title} </div>
</div>`;
          break;
        default:
          title = `<div class="alert alert-primary d-flex align-items-center" role="alert">
  <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Info:"><use xlink:href="#info-fill"/></svg>
  <div>${title}</div>
</div>`;
          break;

      }
      var bdy = `
        <div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">   
          <strong class="me-auto">Thông báo</strong>   
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
         ${title}
        </div>
      </div>
    `;
      var tp = `
    <div id="${tid}" class="position-fixed ${opt} p-3 t" style="z-index: 9999">${bdy}</div>
    `;
      var checkEl = document.body.querySelector("#" + tid);
      if (checkEl) checkEl.remove();
      document.body.insertAdjacentHTML("beforeend", tp);
      var kt = document.body.querySelector("#" + tid + " .toast");
      new bootstrap.Toast(kt).show();
    }
export const lib = {
  uploadTag: async  (multiple = false) => {
    return new Promise((resolve) => {
      var keyU = document.querySelector("#tt-uploads-file");
      if (keyU) {
        keyU.remove();
      }
      document.querySelector("body").insertAdjacentHTML(
        "beforeend",
        `<div id ="tt-uploads-file" style="display:none"><input type="file" name="files" ${multiple? 'multiple': ''} > </div>`,
      );
      document.querySelector("#tt-uploads-file input[type=file]").onchange = (
        evt,
      ) => {
        resolve(evt.target.files);
      };
      document.querySelector("#tt-uploads-file input[type=file]").click();
    });
  },
  slugify: (str) =>  {  
    str = str || '';
    if(typeof str !== 'string') {
      str = JSON.stringify(str);
    }
    str = str.toLowerCase(); 
    str = str
      .normalize('NFD') 
      .replace(/[\u0300-\u036f]/g, ''); 
    str = str.replace(/[đĐ]/g, 'd');
    str = str.replace(/([^0-9a-z-\s])/g, '');
    str = str.replace(/(\s+)/g, '-');
    str = str.replace(/-+/g, '-');
    str = str.replace(/^-+|-+$/g, '');
    return str;
  },
  alert:  {    
     info: (title, opt) => {
        alertInit(title, 'info', opt);
      },
      success: (title, opt) => {
        alertInit(title, 'success', opt);
      },
      warning: (title, opt) => {
        alertInit(title, 'warning', opt);
      },
      error: (title, opt) => {
        alertInit(title, 'error', opt);
      }
  },
  questionAlert: (html, callback, options) => {
    var tid = "yt-alert-question";
    options = options || {};
    options = Object.assign({title:"Thông báo", noName: "Không", yesName: "Có" }, options);
    var bodydiag = `
    <div class="modal fade" style="z-index: 9060;" id="${tid}" data-tmodal="${tid}" data-bs-backdrop="static" 
    data-bs-keyboard="false"  aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">${options.title}</h5>        
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">  ${html}</div>
        <div class="justify-content-center modal-footer">
            <button type="button" class="btn btn-primary yesbtn" style="width: 45%" data-action="yes">${options.yesName}</button>
            <button type="button" class="btn btn-secondary nobtn"  data-action="no" style="width:45%">${options.noName}</button>

          </div>
      </div>
    </div>
  </div>

    `;
    var tidE = document.querySelector("body #" + tid);
    if (tidE) {
      tidE.remove();
    }
    if(typeof tmodal == 'undefined') tmodal = {};
    document.body.insertAdjacentHTML("beforeend", bodydiag);
    var ttidE = document.getElementById(tid);
    tmodal[tid] = new bootstrap.Modal(ttidE, {
      keyboard: false,
    });
    tmodal[tid].show();
    ttidE.addEventListener("hidden.bs.modal", (event) => {
      tmodal[tid].dispose();
    });
    document.querySelector("#" + tid + " button[data-action=yes]")
      .addEventListener("click", (evt) => {
      callback(true);
      tmodal[tid].hide();
    });
    document.querySelector("#" + tid + " button[data-action=no]")
      .addEventListener("click", (evt) => {
      callback(false);
      tmodal[tid].hide();
    });

    return tmodal[tid];
  }
}
export const api = {
  postData: postData,
  docsFind: (data) => postData('/admin/docs-find', data),
  docsCount: (data) => postData('/admin/docs-count', data),
  docsFindOne:(data) => postData('/admin/docs-find-one', data),
  docsInsert:(data) => postData('/admin/docs-insert', data),
  docsCopy:(data) => postData('/admin/docs-copy', data),
  docsUpdate:(data) => postData('/admin/docs-update', data),
  docsDelete:(data) => postData('/admin/docs-delete', data),
  doiMatKhau:(data) => postData('/admin/doi-mat-khau', data),
  getTinhThanh: () => postData("/admin/tinh-thanh"),
  getQuanHuyen: (tid) => postData("/admin/quan-huyen/" + tid),
  logout:() => postData('/admin/logout'),
  uploadFile: async(files = [], data = {path: ''}) => 
  {
    var fmdata = new FormData();   
    for (let i = 0; i < files.length; i++) {
      fmdata.append("files", files[i]);
    }
    Object.keys(data).forEach(fe => {
      if(fe != 'files'){
        fmdata.append(fe, data[fe]);
      }

    })  
	let url = (cache.get('apiHost')? cache.get('apiHost'): '' ) + "/admin/upload-file-folder";
    let dataP = {
      method: 'POST',
      credentials: 'same-origin',     
      body: fmdata,
    }
    if(cache.get('token')) {
      dataP['headers'] = {'Authorization': `Bearer ${cache.get('token')}`};
    }
    const response = await fetch(url , dataP);
    return await response.json();
  },
  files: (data) => postData('/admin/list-file',  data),
  fileDel: (data) => postData('/admin/delete-file', data),
  myApp: () => postData('/admin/my-app'),
  appUpdate: (data) => postData('/admin/app-update', data)
  
  
}