const set = (key, value) => {
    if (value == null) {
        localStorage.removeItem(key);
    } else {
        try {
          let valueStr = value;          
          if(typeof value == 'string') { valueStr= JSON.stringify(value)};
            localStorage.setItem(key,valueStr);
        } catch (e) {
            // May throw exception if not enough memory allocated or in Safari's private mode
        }
    }
};
const dataCache= {
    set: set,
    get(key) {
        let vStr = localStorage.getItem(key);
        if (vStr == null) {
            return null;
        }
      try{
        return JSON.parse(vStr);
      }catch(err) {
        return vStr;
      }
        
    },
    clear(key) {
      localStorage.removeItem(key);
    }
};
const constApp = {
  hostname: "apiHost"
}

export {dataCache as cache, constApp };
