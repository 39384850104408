import React from 'react';
import './style.css';
import {RApi} from './../../common/routerApi';
import {cache} from './../../common/cache';
export default  class LoginRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
      pwd: '',
      isSubmit: false,
      error: false
    }
  }
  async submit(e) {                  
    this.setState({isSubmit: true, error: false});
    let {user, pwd} = this.state;
    let dataR = await RApi.login(user, pwd);
    if(dataR[0] == 200) {
      //cache.set('token', '')
      var {tid, user: userRq, ho_ten, date: ngay} = dataR[1];
      cache.set("info", {user: userRq, hoTen: ho_ten, ngay: ngay});
      cache.set("token", tid);
      window.location.href="/admin/";
    } else {
      alert(dataR[1]);
    }
    this.setState({isSubmit: false, error: false});
  }
  render() {
    let {user, pwd, isSubmit, error} = this.state;
    return (
      <div className="container">
        <div  className="m-auto max450">
          <h1 className="fs-3 mb-4 text-center">
            ĐĂNG NHẬP HỆ THỐNG
          </h1>
          <form id="login" method="post" onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.submit()
            } }>
            <div className="form-floating mb-3">
              <input type="text" className="form-control input" name="user" placeholder="Tên đăng nhập" 
                value={user} onChange = {(e) => this.setState({user: e.target.value})}
                required />
              <label >Tên đăng nhập </label>
            </div>
            <div className="form-floating">
              <input type="password" className="form-control input" name="pwd" placeholder="Mật khẩu"
                value={pwd} onChange= {(e) => this.setState({pwd: e.target.value})}
                required />
              <label >Mật khẩu</label>
            </div>
            <div className="d-flex flex-column justify-content-center mb-3 mt-3">
              <button className="btn btn-primary p-3" data-action="login" type="submit" disabled = {isSubmit}>
                Đăng nhập
              </button>
            </div>

          </form>

        
        </div>
      </div> 

    );
  }
}
