import React, {Fragment} from "react";
import {BrowserRouter, Routes, Route, Switch, Navigate } from 'react-router-dom';
import Main from './layout/Main';
import ToDo from './routers/ToDo/ToDo';
import LoginRoute from './routers/login/login';
import NotFoundRoute from './routers/notfound/notfound';
import DoiMatKhau from './routers/DoiMatKhau/DoiMatKhau';


import {cache} from './common/cache';
export default class App extends React.Component {
  constructor(props) {
    super(props);  
    this.state = {
      baseHref: '/admin'
    }
    
  } 
  componentDidMount() {
    var hostname = ['admin.pianoart.vn'].includes(window.location.hostname) ? '': 'https://admin.pianoart.vn' ;
    if(hostname) {
      this.setState({baseHref: ''});
    }
     cache.set('apiHost', hostname);
    this.handler = (e) => {
      if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
        e.stopImmediatePropagation();
      }
    };

    document.addEventListener("focusin", this.handler); 
  }
  componentDidUnMount() {
    document.removeEventListener("focusin", this.handler);
  }
  render() {
    let token = cache.get('token') || '';    
    const {baseHref} = this.state;
    return (
      <React.StrictMode>
        <BrowserRouter basename={baseHref}>
          <Routes>    
            <Route  path="/login" element={ !token ? <LoginRoute /> : <Navigate to="/" />} />        
            <Route  path="/" element={token ? <Main />: <Navigate to="/login" />}>
              <Route  path="" element={<ToDo />} />
              <Route  path="to-do" element={<ToDo />} />            
              <Route  path="doi-mat-khau" element={<DoiMatKhau />} /> 
              
            </Route>
            <Route  path="/*" element={<NotFoundRoute />} />          
          </Routes>
        </BrowserRouter>  
      </React.StrictMode>
    );
  }  
}
 
