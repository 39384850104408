import React, {Component} from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import  userImg from './../assets/images/user.png';
export default  class Menu extends Component {

  render() {
    return (
      <aside className="aside-menu menu-mobile-hide ">
        <a id="m-menu-close" className="material-icons mobile">close</a>
        <div className="menu-container">
          <div className="sidenav-header d-flex align-items-center justify-content-center">

            <div className="sidenav-header-inner text-center">
              <a >
                <img src={userImg} 
                  alt="person" className="img-fluid rounded-circle" /></a>
              <h2 className="h5">Xin chào </h2>  
            </div>

          </div>
          <div className="main-menu">
            <h5 className="sidenav-heading">Menu</h5>
            <ul id="side-main-menu" className="side-menu list-unstyled">
              <li><Link to="/to-do"><i className="material-icons">description</i>Khách hàng</Link> </li>
              <li><Link to="/doi-mat-khau"><i className="material-icons">description</i> Đổi mật khẩu</Link> </li>

            </ul>
          </div>
        </div>
      </aside>
    );
  }
}

