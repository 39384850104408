import React from 'react';
import {RApi} from './../../common/routerApi';
import {cache} from './../../common/cache';
import {lib} from './../../common/api';
const MF = {
  opwd: '',
  pwd: '',
  rpwd: ''};

export default  class DoiMatKhau extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...MF,
      isSubmit: false,
      error: false
    }
    this.submit = this.submit.bind(this);
    this.resetData = this.resetData.bind(this);
  }
  async submit(e) {                  
    this.setState({isSubmit: true, error: false});
    let {opwd, pwd, rpwd} = this.state;
    if(opwd && pwd !== rpwd) {
       lib.alert.error('Vui lòng kiểm tra lại thông tin');
      this.setState({isSubmit: false, error: false});
      return;
    }
    let dataR = await RApi.changePwd({opwd:opwd, pwd: pwd, rpwd:rpwd});
    if(dataR[0] == 200) {      
      lib.alert.success('Đã đổi thành công');
      cache.clear('token');
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      alert(dataR[1]);
    }
    this.setState({isSubmit: false, error: false});
  }
  resetData() {
    this.setState({opwd: '', pwd: '', rpwd: ''});
  }
  render() {
    let {user, opwd, pwd, rpwd, isSubmit, error} = this.state;
    return (
       <>
        <div className="breadcrumb-holder">
          <div className="container-fluid"> 
            <h5 className="breadcrumb">Đổi mật khẩu             
            </h5></div>
        </div>       
        <div className="body-main">
          <div className="container">
        <div  className="m-auto max450">         
           
            <div className="form-floating mb-2">
              <input type="password" className="form-control input" name="opwd" placeholder="Mật khẩu cũ"
                value={opwd} onChange= {(e) => this.setState({opwd: e.target.value})}
                required />
              <label >Mật khẩu cũ</label>
            </div>
           <div className="form-floating mb-2">
              <input type="password" className="form-control input" name="pwd" placeholder="Mật khẩu"
                value={pwd} onChange= {(e) => this.setState({pwd: e.target.value})}
                required />
              <label >Mật khẩu</label>
            </div>
           <div className="form-floating mb-2">
              <input type="password" className="form-control input" name="rpwd" placeholder="Nhập lại mật khẩu"
                value={rpwd} onChange= {(e) => this.setState({rpwd: e.target.value})}
                required />
              <label >Nhập lại mật khẩu</label>
            </div>
            <div className="d-flex flex-column justify-content-center mb-3 mt-3">
              <button className="btn btn-primary p-3" data-action="login" onClick={this.submit} disabled = {isSubmit}>
               Đổi mật khẩu
              </button>
              <button className="btn btn-danger p-3 mt-2" onClick={this.resetData}>Làm lại</button>
            </div>

        
        
        </div>
      </div> 
        </div>
      </>
     

    );
  }
}
