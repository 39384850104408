import React, {Component} from 'react';
import {cf} from './../../common/system';
import {api, lib} from './../../common/api';
import {cache, constApp} from './../../common/cache';
import validator from 'validator';
import Select from 'react-select';
import Datetime from 'react-datetime';
import Moment from 'moment';
import "./todo.css";
import DatePicker from "./../../components/react-datepicker";
import  "./../../components/react-datepicker/stylesheets/datepicker.scss";


const MF_DATA1 = ["name", "diachi", "tinh_thanh", "quan_huyen", "sodienthoai","email", "diachi", "tensp", "model", "serial", "ngay" , "sobaohanh", "giaban"];
const MF_DATA2 = ["ghi_chu", "ngay_ghi_chu", "theo_doi","canh_bao", "log_ghi_chu"];
const MF = {
  name: '',  
  diachi: '',
  sodienthoai: '',
  email: '',
  tinh_thanh: null,
  quan_huyen:null,
  diachi: '',
  tensp: '',
  model: '',
  serial: '',  
  giaban: 0,
  ngay: new Date(),
  sobaohanh:0,
  ghi_chu:'',
  ngay_ghi_chu: new Date(),
  theo_doi: {tid: '', name: 'Không theo dõi'},
  canh_bao: 7, // Cảnh báo sau ngày,
  log_ghi_chu: []
}
const MF_GHI_CHU = {
  name: '',
  ngay: new Date(),
  type: 'theodoi',
  tinh_trang: {tid: '', name: 'Không theo dõi'}
}
const THEO_DOI = [{tid: 'theodoi', name: 'Theo dõi'},
                  {tid: 'khongtheodoi', name: 'Không theo dõi'},
                  {tid: 'hoanthanh', name: 'Hoàn thành'},
                  {tid: '', name: 'Không thiết lập'}
                 ];
export default  class ToDo extends Component {
  constructor(props) {
    
    super(props);    
    this.state = {
      isShow: false,
      ac: ['add', '', MF_DATA1],
      mf:{...MF},
      field_show: [...MF_DATA1, ...MF_DATA2],
      mf_ghichu: {...MF_GHI_CHU},
      listData: [],     
      isSubmit: false,
      error: false,
      type_doc: 'todo',
      is_active: 'theodoi',
      theodoi: 0,
      khongtheodoi: 0,
      canhbao: 0,
      hoanthanh: 0,
      hethan: 0,
      todo: 0,
      tinh_thanh: [],
      quan_huyen: [],
      keyword: '',
     d_start: this.getDateNow(),
     d_end: new Date()
    };
    this.handleSelect = this.handleSelect.bind(this);    
    this.handGhiChuModal = this.handGhiChuModal.bind(this);
    this.handChange = this.handChange.bind(this);
    this.handChangeDate = this.handChangeDate.bind(this);
    this.saveData2 = this.saveData2.bind(this);
    this.saveData = this.saveData.bind(this);
    this.activeData = this.activeData.bind(this);
    this.resetData = this.resetData.bind(this);
  }
  componentDidMount(){
    let myOffcanvas = document.getElementById('offcanvasExample'); 
    let myGhiChuModal = document.getElementById('ghichuModal'); 
    let bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);   
    this.offcavas1 = bsOffcanvas;   
    this.ghiChuModal = new bootstrap.Modal(myGhiChuModal, {
      keyboard: false
    });
    (async()=> {    
      this.loadData();
      this.loadCountAll();
      this.loadTinhThanh();
    })();


  }
  componentWillUnmount() {

  }
  getDateNow() {
    var dateNow = new Date();
   return new Date(dateNow.getFullYear()-1,dateNow.getMonth(), dateNow.getDate() )
  }
  async loadTinhThanh() {
    var kq = await api.getTinhThanh();
    if(kq[0] == 200) {
      this.setState({tinh_thanh: kq[1]});
    } else {
      this.setState({tinh_thanh: []});
    }
  }
  async loadQuanHuyen(tid) {
    var kq = await api.getQuanHuyen(tid);
    if(kq[0] == 200) {
      this.setState({quan_huyen: kq[1]});
    } else {
      this.setState({quan_huyen: []});
    }
  }

  async loadCountAll() {
    this.loadCount('theodoi');
    this.loadCount('khongtheodoi');
    this.loadCount('canhbao');
    this.loadCount('hoanthanh');
    this.loadCount('hethan');
    this.loadCount('todo');
  }
  async saveData() {   
    this.setState({isSubmit: true, error: false});
    let {mf, type_doc, ac} = this.state;
    // console.log(11111,mf, ac);
    var data_field = ac[2];
    var kk_data = {};
    data_field.forEach(fe => {
      kk_data[fe] = mf[fe];
    });
    if(validator.isEmpty((mf.name || ''))) {
      lib.alert.error('Vui lòng nhập trường tên');
      this.setState({isSubmit: false, error: false});
      return;
    }

    var dataRes = {type:type_doc, data: {...kk_data}};
    // Chuyển ngày sang hàm
    if(dataRes["data"]["ngay"]) {
      dataRes["data"]["ngay"] = `fn_$(new Date("${dataRes["data"]["ngay"]}"))`;
    }
    if(dataRes["data"]["ngay_ghi_chu"]) {
      dataRes["data"]["ngay_ghi_chu"] = `fn_$(new Date("${dataRes["data"]["ngay_ghi_chu"]}"))`;
    }

    if(ac[0] == 'add') {            
      var kq = await api.docsInsert(dataRes);
      if(kq[0] == 200) {
        lib.alert.success('Đã cập nhật thành công');
        await this.loadData();
        this.setState({ac: ['edit', kq[1], data_field]});
      } else {
        lib.alert.error('Lưu thất bại, gọi kỹ thuật hỗ trợ');
      }
      this.setState({isSubmit: false, error: false});
    } else {
      dataRes['tid'] = ac[1];
      var kq = await api.docsUpdate(dataRes);
      if(kq[0] == 200) {
        lib.alert.success('Đã cập nhật thành công');
        await this.loadData();
        this.setState({isSubmit: false, error: false});
      } else {
        lib.alert.error('Lưu thất bại, gọi kỹ thuật hỗ trợ');
      }
      this.setState({isSubmit: false, error: false});
    }
    this.loadCountAll();

  }
  async saveData2() {   
    this.setState({isSubmit: true, error: false});
    let {mf, type_doc} = this.state;
    let {ac} = this.state;
    var data_field = ac[2];
    var kk_data = {};
    data_field.forEach(fe => {
      kk_data[fe] = mf[fe];
    });


    var dataRes = {type:type_doc, data: {...kk_data}};
    // Chuyển ngày sang hàm
    if(dataRes["data"]["ngay"]) {
      dataRes["data"]["ngay"] = `fn_$(new Date("${dataRes["data"]["ngay"]}"))`;
    }
    if(dataRes["data"]["ngay_ghi_chu"]) {
      dataRes["data"]["ngay_ghi_chu"] = `fn_$(new Date("${dataRes["data"]["ngay_ghi_chu"]}"))`;
    }


    if(ac[0] == 'edit')  {
      dataRes['tid'] = ac[1];
      var kq = await api.docsUpdate(dataRes);
      if(kq[0] == 200) {
        lib.alert.success('Đã cập nhật thành công');
        await this.loadData();
        this.setState({isSubmit: false, error: false});
        this.ghiChuModal.hide();
      } else {
        lib.alert.error('Lưu thất bại, gọi kỹ thuật hỗ trợ');
      }
      this.setState({isSubmit: false, error: false});
    }
    this.loadCountAll();

  }
  async deleteData(tid, name) {
    lib.questionAlert(`Có chắc xóa bản ghi của <strong> ${name} </strong> không?`, async (d) => {
      if(d) {
        var {type_doc} = this.state;
        var resData = await api.docsDelete({tid: tid, type: type_doc});
        if(resData[0] == 200) {
          lib.alert.success('Đã xóa thành công');
          await this.loadData();
        } else {
          lib.alert.error(resData[1]);
        }
      }
    });

  }
  resetData() {
    this.setState({ ac: ['add', '', MF_DATA1], mf:{...MF} });

  }
  async activeData(type_doc, evt) { 
    var field_show = [];
    switch(type_doc) {
      case 'todo':  
        field_show = [...MF_DATA1]  ;      
        break;
      default:
        field_show = [...MF_DATA1, ...MF_DATA2];
    }
    this.setState({field_show: field_show, is_active:type_doc}, async() => {
      await this.loadData();
    })
  }
  async loadCount(is_active) {  
    var {type_doc} = this.state;
    var queryObject = {};
    switch(is_active) {
      case 'theodoi':
        queryObject["theo_doi.tid"] = "theodoi";
        break;
      case 'hoanthanh':
        queryObject["theo_doi.tid"] = "hoanthanh";
        break;
      case 'khongtheodoi':
        queryObject["theo_doi.tid"] = "khongtheodoi";
        break;
      case 'canhbao':
        queryObject = [
          {$match: {"theo_doi.tid": "theodoi", sobaohanh: {$type: "int"}, canh_bao: {$type: "int"}}},
          {$addFields: {
            check_cach_bao: {$subtract: [{$subtract: [ {$add: ["$ngay",  {$multiply: ["$sobaohanh", 12*30*24*60*60*1000]} ]}, {$dateFromString: {dateString:new Date() }}]}, {$multiply: ["$canh_bao", 24*60*60*1000]} ]}
          }},
          {$match: {check_cach_bao: {$gt: 0}}}

        ];
        break;
      case 'hethan':
        queryObject = [
          {$match: {"theo_doi.tid": "theodoi", sobaohanh: {$type: "int"}, canh_bao: {$type: "int"}}},
          {$addFields: {
            check_cach_bao: {$subtract: [{$subtract: [ {$add: ["$ngay",  {$multiply: ["$sobaohanh", 12*30*24*60*60*1000]} ]}, {$dateFromString: {dateString:new Date() }} ]}, {$multiply: ["$canh_bao", 24*60*60*1000]} ]}
          }},
          {$match: {check_cach_bao: {$lt: 0}}}

        ];
        break
    }
    let queryData = {type:type_doc,query: queryObject};    
    let reqData = await api.docsCount(queryData);   
    // console.log(is_active);
    if(reqData[0] == 200) {      
      this.setState({[is_active]:reqData[1] });
    } else {
      this.setState({[is_active]:0 })
    }
  }
  async loadData() {  
    var {type_doc, field_show, is_active} = this.state;  
    var data_field = {_id: 0, tid: 1};
    field_show.forEach(fe => {
      data_field[fe] = 1;
    })
    var queryObject = {};
    switch(is_active) {
      case 'theodoi':
        queryObject["theo_doi.tid"] = "theodoi";
        break;
      case 'hoanthanh':
        queryObject["theo_doi.tid"] = "hoanthanh";
        break;
      case 'khongtheodoi':
        queryObject["theo_doi.tid"] = "khongtheodoi";
        break;
      case 'canhbao':
        queryObject = [
          {$match: {"theo_doi.tid": "theodoi",  sobaohanh: {$type: "int"}, canh_bao: {$type: "int"}}},
          {$addFields: {
            check_cach_bao: {$subtract: [{$subtract: [ {$add: ["$ngay",  {$multiply: ["$sobaohanh", 12*30*24*60*60*1000]} ]}, {$dateFromString: {dateString:new Date() }} ]}, {$multiply: ["$canh_bao", 24*60*60*1000]} ]}
          }},
          {$match: {check_cach_bao: {$gt: 0}}},
          {$project: data_field}
        ];
        break;
      case 'hethan':
        queryObject = [
          {$match: {"theo_doi.tid": "theodoi", sobaohanh: {$type: "int"}, canh_bao: {$type: "int"}}},
          {$addFields: {
            check_cach_bao: {$subtract: [{$subtract: [ {$add: ["$ngay",  {$multiply: ["$sobaohanh", 12*30*24*60*60*1000]} ]}, {$dateFromString: {dateString:new Date() }} ]}, {$multiply: ["$canh_bao", 24*60*60*1000]} ]}
          }},
          {$match: {check_cach_bao: {$lt: 0}}},
          {$project: data_field}
        ];
        break
    }
    let queryData = {type:type_doc, active:is_active,  query: queryObject, options: {
      projection: data_field, sort: {ngay:-1}}}; 
    // console.log(data_field);
    let reqData = await api.docsFind(queryData);   
    if(reqData[0] == 200) {
      this.setState({listData: reqData[1]});
    } else {
      this.setState({listData: []});
    }
  } 
  handleSelect = (evt,name) => {
    // console.log(evt, name);
    let {mf} = this.state;
    var value = evt;
    this.setState({mf: {...mf, [name]: value}});
    if(name == 'tinh_thanh') {
      this.loadQuanHuyen(evt.ma).then(m => {

      })
    }
  }

  handChangeDate = (fieldName, valueMoment, ttype) => {
    let {mf} = this.state;
    var name = fieldName;
    var value  = Moment(valueMoment).toDate().toJSON();
    this.setState({mf: {...mf, [name]: value}});

  }
  handChange = (event) => {   
    let {name, value, type: ttype} = event.target; 
    let {mf} = this.state;
    if(ttype == 'number') {
      value = /^\d+$/.test(value)? Number(value): 0;
    }
    var datachange = {...mf, [name]: value};
    // console.log(datachange);
    this.setState({mf: datachange});

  } 
  async handGhiChuModal (tid) {
    if(tid) {
      this.setState({ac: ['edit', tid, MF_DATA2]});
      var {type_doc} = this.state;
      var option_project = {_id: 0, tid: 1};
      MF_DATA2.forEach(fe => {
        option_project = {...option_project, [fe]: 1};
      })

      let resData = await api.docsFindOne({type: type_doc,tid: tid, 
                                           options: {
                                             projection: option_project}});
      if(resData[0] == 200) {      
        let mfData = resData[1];
        this.setState({mf:{...MF, ...(mfData || {})}} ); 
      } else {
        lib.alert.error('Không tìm thấy bản ghi phù hợp');
        return;
      }
    } else {
      this.setState({ac: ['add', '', MF_DATA1]});
      this.setState({mf:MF });
    }
    this.ghiChuModal.show();
  }
  async openOffcanvas(tid) {

    if(tid) {
      this.setState({ac: ['edit', tid, MF_DATA1]});
      var {type_doc} = this.state;
      var option_project = {_id:0, tid: 1};
      MF_DATA1.forEach(fe => {
        option_project = {...option_project, [fe]: 1};
      })
      let resData = await api.docsFindOne({type: type_doc,tid: tid, 
                                           options: {
                                             projection: option_project}});
      if(resData[0] == 200) {      
        let mfData = resData[1];        
        this.setState({mf:{...MF, ...(mfData || {})}} ); 
        if(mfData.quan_huyen) {
          this.loadQuanHuyen(mfData.quan_huyen.thuoc_ma).then(([status, data])=> {
            if(status == 200) {
              this.setState({quan_huyen: data});
            }
          });
        }
      } else {
        lib.alert.error('Không tìm thấy bản ghi phù hợp');
        return;
      }
    } else {
      this.setState({ac: ['add', '', MF_DATA1]});
      this.setState({mf:MF });
    }

    this.offcavas1.show();
  }  
  render() {
    let {isShow, ac, listData = [],field_show,  isSubmit,error, is_active,
         tinh_thanh, quan_huyen, keyword= null, d_start, d_end } = this.state;
    //console.log(tinh_thanh);
    let listData2 = [];
    const hotname = cache.get(constApp.hostname);
    const { mf, mf_ghichu, theodoi,khongtheodoi, canhbao, hoanthanh, hethan, todo, type_doc} = this.state;
    
    listData2 = listData.filter(f => {
      var t = new Date(f.ngay_ghi_chu).getTime();
      var t2 = new Date(f.ngay).getTime();
      var tstart = new Date(d_start).getTime();
      var tend = new Date(d_end).getTime();
      var fTinhThanh = true;
      if(keyword) {
        fTinhThanh = (f.tinh_thanh?.ma ?? '') == (keyword.ma ?? '');
      }
      return ((t >= tstart && t <= tend) || (t2 >= tstart && t2 <= tend)) && fTinhThanh;
    });
    
    return (      
      <>
        <div className="breadcrumb-holder">
          <div className="container-fluid"> 
            <h5 className="breadcrumb">Theo dõi khách hàng
              <button className="btn btn-primary material-icons" type="button" onClick={() => this.openOffcanvas()}>
                add
              </button>
            </h5></div>
        </div>       
        <div className="body-main">
          <div className="container list-action">
            <button type="button" className={'btn btn-primary me-3' + (is_active == 'theodoi'? ' btn-active': '') } id="theodoi" onClick={(evt) => this.activeData('theodoi', evt)}>Theo dõi <span className="badge badge-light">{theodoi}</span></button>
            <button type="button" className={"btn btn-info me-3" + (is_active == 'khongtheodoi'? ' btn-active': '' )} id="khongtheodoi" onClick={(evt) => this.activeData('khongtheodoi', evt)} >Không theo dõi <span className="badge badge-light">{khongtheodoi}</span></button> 
            <button type="button" className={"btn btn-warning me-3" + (is_active == 'canhbao'? ' btn-active': '')} id="canhbao" onClick={(evt) => this.activeData('canhbao', evt)} >Cảnh báo <span className="badge badge-light">{canhbao}</span></button>
            <button type="button" className={"btn btn-success me-3" + (is_active == 'hoanthanh'? ' btn-active': '')} id="hoanthanh" onClick={(evt) => this.activeData('hoanthanh', evt)}>Hoàn thành <span className="badge badge-light">{hoanthanh}</span></button>
            <button type="button" className={"btn btn-danger me-3" + (is_active == 'hethan'? ' btn-active': '')} id="hethan" onClick={(evt) => this.activeData('hethan', evt)} >Hết hạn <span className="badge badge-light">{hethan}</span></button>
            <button type="button" className={"btn btn-info me-3" + (is_active == 'todo'? ' btn-active': '' )} id="todo" onClick={(evt) => this.activeData('todo', evt)} >Danh sách <span className="badge badge-light">{todo}</span></button> 

          </div>
          <div className="mt-3">
            <div className="date-search row container">
              <div className="col-md-3">
                <label>Từ ngày</label>
                <DatePicker selected={d_start} dateFormat="dd/MM/yyyy" onChange={(date) => this.setState({d_start: date})} />
              </div>
              <div className="col-md-3">
                <label>Đến ngày</label>
                <DatePicker selected={d_end} dateFormat="dd/MM/yyyy" onChange={(date) => this.setState({d_end: date})} />
              </div>
              <div className="col-md-6">
                <label>Tỉnh</label>
                <Select
                  value={keyword}  
                  isClearable={true}
                  clearValue={() => this.setState({keyword:null})}
                  getOptionLabel={(pt) =>  pt.ten}
                  getOptionValue = {(pt) => pt.ma}
                  onChange={(evt) => {this.setState({keyword: evt})}}
                  options={tinh_thanh}
                  />
              </div>
              
            </div>
            
          </div>
          <table className="table">
            <thead>
              <tr>
                <th width="80">#</th>                
                <th width="180">Ngày</th>
                <th width="180">Khách hàng</th>
                <th> Tỉnh thành</th>
                <th width="180">Điện thoại</th>
                <th>Đơn hàng</th> 
                <th className={field_show.length != MF_DATA1.length? '': 'd-none'}>Ghi chú</th>                
                <th width="100"></th>
              </tr>
            </thead>
            <tbody>

              {listData2.map((m,idx) => {
                return (
                  <tr key={m.tid}>
                    <td scope="row">{idx +1}</td>
                    {field_show.length != MF_DATA1.length ? (
                      <td>{m && m.ngay_ghi_chu ? Moment(m.ngay_ghi_chu).format('DD/MM/YYYY'): ''}</td>
                    ): (
                      <td>{Moment(m.ngay).format('DD/MM/YYYY')}</td>
                    )}                 
                    <td> {m.name} </td>
                    <td> {m?.tinh_thanh?.ten ?? ''} </td>
                    <td>{m.sodienthoai}  </td>
                    <td>
                      <p><strong>{m.tensp}</strong></p>
                      {type_doc != 'todo'? (<p>Ngày: {Moment(m.ngay).format('DD/MM/YYYY')}</p>): ''}
                      <em>
                        <span>Model: <strong>{m.model}</strong>;</span>
                        <span>Serial: <strong>{m.serial}</strong>;</span>
                        <span>Giá: <strong>{(m.giaban + '').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</strong>;</span>
                        <p>Bảo hành: <strong>{m.sobaohanh}</strong></p>
                      </em> 
                    </td> 
                    <td className={field_show.length != MF_DATA1.length ? '': 'd-none'}>
                      {m.ghi_chu}
                    </td><td>
                    <span className="material-icons pointer" onClick={() => this.handGhiChuModal(m.tid)}>subject</span>
                    <span className="material-icons pointer" onClick={() => this.openOffcanvas(m.tid)}> edit</span> 
                    <span className="material-icons pointer" onClick={() => this.deleteData(m.tid, m.name)}> delete</span> 
                    </td>

                  </tr> 

                )

              }) }
            </tbody>
          </table>
        </div>
        <div className="offcanvas offcanvas-end" 
          id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" 
          style={{width: "calc(100% - 300px)"}}>
          <div className="offcanvas-header title-modal">
            <h5 className="offcanvas-title" id="offcanvasExampleLabel">{ac[0] == 'add'? 'Thêm mới': 'Chỉnh sửa'}           
            </h5>
            <div>
              <button className="btn btn-primary" onClick={ this.saveData} disabled = {isSubmit}>                
                Cập nhật</button> 
              <button className={ac[0] == 'edit'? "btn btn-danger ms-3": "d-none"}  onClick={ this.resetData}>
                Hủy và thêm mới</button>
            </div>
            <div>
              <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
          </div>
          <div className="offcanvas-body">
            <div className="container">
              <div className="row">
                <div className="col-md-6">

                  <div className="mb-3">
                    <label className="form-label">Tên Khách hàng</label>
                    <input type="text" className="form-control" name="name"  placeholder="Tên KH" value={mf.name || ''} 
                      onChange={this.handChange}
                      />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Số điện thoại</label>
                    <input type="text" className="form-control" name="sodienthoai"  placeholder="Điện thoại"
                      value={mf.sodienthoai || ''} 
                      onChange={this.handChange}
                      />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Email </label>
                    <input type="text" className="form-control" name="email"  placeholder="Email"
                      value={mf.email || ''} 
                      onChange={this.handChange}
                      />
                  </div>
                  <div className="mb-3">
                    <div className="row">
                      <div className="mb-3 col-md-4">
                        <label className="form-label">Tỉnh thành</label>
                        <Select
                          value={mf.tinh_thanh}                         
                          getOptionLabel={(pt) =>  pt.ten}
                          getOptionValue = {(pt) => pt.ma}
                          onChange={(evt) => this.handleSelect(evt, 'tinh_thanh')}
                          options={tinh_thanh}
                          />
                      </div>
                      <div className="mb-3 col-md-4">
                        <label className="form-label">Quận huyện</label>
                        <Select
                          value={mf.quan_huyen}                         
                          getOptionLabel={(pt) =>  pt.ten}
                          getOptionValue = {(pt) => pt.ma}
                          onChange={(evt) => this.handleSelect(evt, 'quan_huyen')}
                          options={quan_huyen}
                          />
                      </div>
                      <div className="mb-3 col-md-4">
                        <label className="form-label">Địa chỉ</label>
                        <input type="text" className="form-control" name="diachi"  placeholder="Địa chỉ"
                          value={mf.diachi || ''} 
                          onChange={this.handChange}
                          />
                      </div>
                    </div>
                  </div>

                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label">Tên đàn</label>
                    <input type="text" className="form-control" name="tensp" placeholder="Tên đàn"
                      value={mf.tensp || ''} 
                      onChange={this.handChange}
                      />
                  </div>
                  <div className="row">
                    <div className="col-md-6"> <div className="mb-3">
                      <label className="form-label">Model</label>
                      <input type="text" className="form-control" name="model" placeholder="Model"
                        value={mf.model || ''} 
                        onChange={this.handChange}
                        />
                      </div></div>
                    <div className="col-md-6"> <div className="mb-3">
                      <label className="form-label">Serial</label>
                      <input type="text" className="form-control" name="serial" placeholder="Serial - No"
                        value={mf.serial || ''} 
                        onChange={this.handChange}
                        />
                      </div></div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Giá bán</label>
                    <input type="text" className="form-control" name="giaban" placeholder="Giá bán"
                      value={mf.giaban || ''} 
                      onChange={this.handChange}
                      />
                  </div> 
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Ngày bán</label>
                        <Datetime value={Moment(mf.ngay)} 
                          dateFormat="DD-MM-YYYY"  
                          timeFormat={false}
                          onChange={(evt) => {this.handChangeDate('ngay', evt)}} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Bảo hành (năm)</label>
                        <input type="text" className="form-control" name="sobaohanh" placeholder="Bảo hành"
                          value={mf.sobaohanh || ''} 
                          onChange={this.handChange}
                          />
                      </div>
                    </div>
                  </div>                                    
                </div>
              </div>
            </div>


          </div>
        </div>
        <div className="modal fade" id="ghichuModal"  aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" >Theo dõi</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">Trạng thái theo dõi</label>
                      <Select
                        value={mf.theo_doi}                         
                        getOptionLabel={(pt) =>  pt.name}
                        getOptionValue = {(pt) => pt.tid}
                        onChange={(evt) => this.handleSelect(evt, 'theo_doi')}
                        options={THEO_DOI}
                        />
                    </div>
                    <div className={"mb-3" + (mf.theo_doi.tid == ""? " d-none": "")}>
                      <label className="form-label">Nội dung</label>
                      <input type="text" className="form-control" name="ghi_chu"  placeholder="Ghi chú"
                        value={mf.ghi_chu || ''} 
                        onChange={this.handChange}
                        />
                    </div>

                    <div className={"mb-3" + (mf.theo_doi.tid ==""? " d-none": "")}>
                      <label className="form-label">Cảnh báo {mf.canh_bao} ngày</label>
                      <input type="number" className="form-control" name="canh_bao"  placeholder="Cảnh báo"
                        value={mf.canh_bao} 
                        onChange={this.handChange}
                        />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Đóng</button>
                <button type="button" className="btn btn-primary" onClick={this.saveData2}>Cập nhật</button>
              </div>
            </div>
          </div>
        </div>
      </>

    );
  }

}
