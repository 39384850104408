import React from 'react';

export default  class Footer extends React.Component {
  
  render() {
    return (
    <section className="footer"></section>
    );
  }
}
