import {cache, constApp} from './cache';
String.prototype.str2tp = function (params) {
  const names = Object.keys(params);
  const vals = Object.values(params);
  const tp = this.replace(/&lt;/g,'<').replace(/&gt;/g,'>').replace(/&amp;/g,'&');
  return new Function(...names, `return \`${tp}\`;`)(...vals);
};
export const cf = {};
cf.l = new class {
  constructor(){
   if(typeof window.tmodal  == 'undefined') {window.tmodal = {}};
    this.baseUrl = cache.get(constApp.hostname)? cache.get(constApp.hostname): '';
  }
  async loadScriptAsync(url) {
    //console.log(url);
    return new Promise((resolve) => {
      var tid = btoa(url);
      if (document.querySelector('script[id="' + tid + '"]')) {
        resolve(true);
      } else {
        var head = document.head;
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = tid;
        script.onreadystatechange = () => {
          resolve(true);
        };
        script.onload = () => {
          resolve(true);
        };
        head.appendChild(script);
      }
    });
  }
  async postData(url = "", data = {}) {
    try{
           
      const response = await fetch(url, {
        method: "POST",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(data),
      });
      var t = await response.json();
      return t;
    }catch(err) {
      return [201, err.message];
    }

  }
  async getData(url = "") {   
    const response = await fetch(url);
    return response.text();
  }
  getOffsetElement(el) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
    };
  }
  copy(text) {
    if (window.clipboardData && window.clipboardData.setData) {
      // IE specific code path to prevent textarea being shown while dialog is visible.
      return clipboardData.setData("Text", text); 

    } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
      var textarea = document.createElement("textarea");
      textarea.textContent = text;
      textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        return document.execCommand("copy");  // Security exception may be thrown by some browsers.
      } catch (ex) {
        console.warn("Copy to clipboard failed.", ex);
        return false;
      } finally {
        document.body.removeChild(textarea);
      }
    }
  }
  spinner(btn, show) {
    if (btn) {
      if (show == true) {
        btn.setAttribute("disabled", "");
        btn.insertAdjacentHTML(
          "afterbegin",
          `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>`,
        );
      } else {
        btn.removeAttribute("disabled");
        let btnchild = btn.querySelector("span[class*=spinner-border]");
        if (btnchild) {
          btnchild.remove();
        }
      }
    }
  }
  printHtml(divContents) {
    var a = window.open("", "","height=" + window.screen.height + ", width=" + window.screen.width);
    a.document.write(divContents);
    a.document.close();
    a.addEventListener("afterprint", (event) => {
      a.close();
    });
    a.print();
  }
  get alert() {
    // @options = "tl|tc|tr|ml|mc|mr|bl|bc|br"
    var finit = (title, type, options)  => {
      var tid = "gdk_alert_bbbb";
      var opt = '';
      switch(options) {
        case "tl":
          opt = 'top-0 start-0';
          break;
        case "tc":
          opt = 'top-0 start-50 translate-middle-x';
          break;
        case "tr":
          opt = "top-0 end-0";
          break;
        case "ml":
          opt = 'top-50 start-0 translate-middle-y';
          break;
        case "mc":
          opt = "top-50 start-50 translate-middle";
          break;
        case "mr":
          opt = 'top-50 end-0 translate-middle-y';
          break;
        case "bl":
          opt = 'bottom-0 start-0';
          break;
        case "bc":
          opt = 'bottom-0 start-50 translate-middle-x';
          break;
        case "br":
          opt = 'bottom-0 end-0';
          break;
        default:
          opt = 'bottom-0 end-0';
          break;
      }
      switch(type){
        case 'error':
          title = `<div class="alert alert-danger d-flex align-items-center" role="alert">
  <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill"/></svg>
  <div>${title}</div>
</div>`;
          break;
        case 'warning':
          title = `<div class="alert alert-warning d-flex align-items-center" role="alert">
  <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Warning:"><use xlink:href="#exclamation-triangle-fill"/></svg>
  <div>${title}</div>
</div>`;
          break;
        case 'success':
          title = `<div class="alert alert-success d-flex align-items-center" role="alert">
  <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-circle-fill"/></svg>
  <div>${title} </div>
</div>`;
          break;
        default:
          title = `<div class="alert alert-primary d-flex align-items-center" role="alert">
  <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Info:"><use xlink:href="#info-fill"/></svg>
  <div>${title}</div>
</div>`;
          break;

      }
      var bdy = `
        <div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">   
          <strong class="me-auto">Thông báo</strong>   
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
         ${title}
        </div>
      </div>
    `;
      var tp = `
    <div id="${tid}" class="position-fixed ${opt} p-3 t" style="z-index: 9999">${bdy}</div>
    `;
      var checkEl = document.body.querySelector("#" + tid);
      if (checkEl) checkEl.remove();
      document.body.insertAdjacentHTML("beforeend", tp);
      var kt = document.body.querySelector("#" + tid + " .toast");
      new bootstrap.Toast(kt).show();
    }
    return {
      info: (title, opt) => {
        finit(title, 'info', opt);
      },
      success: (title, opt) => {
        finit(title, 'success', opt);
      },
      warning: (title, opt) => {
        finit(title, 'warning', opt);
      },
      error: (title, opt) => {
        finit(title, 'error', opt);
      }

    }
  }
  bgSpin(show) {
    var tp = `<div id="spin-bg" style="display:none;position: fixed;width: 100%;height: 100%;background: #112233bf; top: 0;left: 0;z-index: 9999;"> 
	<div class="spinner-grow" style="width: 6rem; height: 6rem; text-align: center;background: #e9ecef;position: relative; top: 50%;left: 50%;" role="status">
  <span class="visually-hidden">Loading...</span></div></div>`;
    var tidE = document.querySelector("body #spin-bg");
    if (!tidE) {
      document.body.insertAdjacentHTML("beforeend", tp);
    }    
    tidE = document.getElementById("#spin-bg");
    if(show) {
      tidE.display = 'block';
    } else {
      tidE.display = 'none';
    }

  }
  questionAlert(html, callback, options) {
    var tid = "yt-alert-question";
    options = options || {};
    options = Object.assign({title:"Thông báo", noName: "Không", yesName: "Có" }, options);
    var bodydiag = `
    <div class="modal fade" style="z-index: 9060;" id="${tid}" data-tmodal="${tid}" data-bs-backdrop="static" 
    data-bs-keyboard="false"  aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">${options.title}</h5>        
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">  ${html}</div>
        <div class="justify-content-center modal-footer">
            <button type="button" class="btn btn-primary yesbtn" style="width: 45%" data-action="yes">${options.yesName}</button>
            <button type="button" class="btn btn-secondary nobtn"  data-action="no" style="width:45%">${options.noName}</button>

          </div>
      </div>
    </div>
  </div>

    `;
    var tidE = document.querySelector("body #" + tid);
    if (tidE) {
      tidE.remove();
    }
    document.body.insertAdjacentHTML("beforeend", bodydiag);
    var ttidE = document.getElementById(tid);
    tmodal[tid] = new bootstrap.Modal(ttidE, {
      keyboard: false,
    });
    tmodal[tid].show();
    ttidE.addEventListener("hidden.bs.modal", (event) => {
      tmodal[tid].dispose();
    });
    document.querySelector("#" + tid + " button[data-action=yes]")
      .addEventListener("click", (evt) => {
      callback(true);
      tmodal[tid].hide();
    });
    document.querySelector("#" + tid + " button[data-action=no]")
      .addEventListener("click", (evt) => {
      callback(false);
      tmodal[tid].hide();
    });

    return tmodal[tid];
  }
  async  compressImg(file, quality = 75, output_format = "") {
    var mime_type;
    var ext = file.name.split('.').pop();
    if(!['jpg', 'png', 'webp'].includes(ext.toLowerCase())) {
      return file;
    }
    if (output_format === "png") {
      mime_type = "image/png";
    } else if (output_format === "webp") {
      mime_type = "image/webp";
    } else if (output_format == "jpg") {
      mime_type = "image/jpeg";
    } else {
      mime_type = file.type;
    }

    return new Promise((resolve) => {
      var f = file;
      var extenFile = f.name.split(".").pop();
      extenFile = extenFile.toLowerCase();
      var fileName = f.name.substr(0, f.name.length - extenFile.length - 1);
      var img = new Image();
      img.src = URL.createObjectURL(f);
      img.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = img.width; //naturalWidth
        canvas.height = img.height; //naturalHeight
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        canvas.toBlob(
          function (blob) {
            var filex = (output_format ? output_format : extenFile);
            if (extenFile !== filex || file.size > blob.size) {
              var f2 = new File([blob], fileName + "." + filex);
              resolve(f2);
            } else {
              resolve(f);
            }
          },
          mime_type,
          quality / 100,
        );
      };
    });
  }
  async  uploadTag() {
    return new Promise((resolve) => {
      var keyU = document.querySelector("#tt-uploads-file");
      if (keyU) {
        keyU.remove();
      }
      document.querySelector("body").insertAdjacentHTML(
        "beforeend",
        `<div id ="tt-uploads-file" style="display:none"><input type="file" name="files" multiple> </div>`,
      );
      document.querySelector("#tt-uploads-file input[type=file]").onchange = (
        evt,
      ) => {
        resolve(evt.target.files);
      };
      document.querySelector("#tt-uploads-file input[type=file]").click();
    });
  }
  formModal( tid, title, headerHtml, dataHtml, sw = 0, options) {
    options = Object.assign({backdrop: "static", keyboard: false }, options || {});
    var ssw = "";
    switch (sw) {
      case 1: {
        ssw = "modal-sm";
        break;
      }
      case 2: {
        ssw = "modal-lg";
        break;
      }
      case 3: {
        ssw = "modal-xl";
        break;
      }
      case 4: {
        ssw = "modal-fullscreen";
        break;
      }
      default: {
        ssw = "";
      }
    } 
    var ttdata = `

      <div class="modal fade" style="background: #00000061;" id="${tid}" data-tmodal="${tid}" ${options.backdrop? 'data-bs-backdrop="static"': ''}  ${options.keyboard? 'data-bs-keyboard="true"': 'data-bs-keyboard="false"'} data-bs-focus ="true" tabindex="-1" aria-labelledby="${tid}Label" aria-hidden="true">
    <div class="modal-dialog ${ssw}">
      <div class="modal-content">
        <div class="modal-header p-2">
          <h5 class="modal-title me-auto" id="${tid}Label">${title}</h5>
          ${headerHtml}
          <button type="button" class="btn-close ms-3" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
        <div class="modal-body">
         ${dataHtml}
    </div>

    		</div>
    	 </div>
    </div> `;
    var ttidE = document.querySelector("body #" + tid);
    if (ttidE) {
      ttidE.remove();
    }

    document.querySelector("body").insertAdjacentHTML("beforeend", ttdata);
    ttidE = document.getElementById(tid);
    if(options.zIndex) {
      ttidE.style.zIndex = options.zIndex;
    }
    if(options.maxWidth) {
      ttidE.querySelector('.modal-dialog').style.maxWidth = options.maxWidth;
    }
    tmodal[tid] = new bootstrap.Modal(ttidE);
    tmodal[tid].show();

    ttidE.addEventListener("hidden.bs.modal", (event) => {
      if(typeof  tmodal[tid].onclose == 'function') {
        tmodal[tid].onclose();
      }
      tmodal[tid].dispose();
      ttidE.remove();
      delete tmodal[tid];
    });
    return tmodal[tid];
  }
  async createTinymce(selector, options = {}){
    try{
      options = {
        url: '',
        content_style: '',
        content_css: '',
        link_list: [],
        image_list: [],
        image_class_list: [],
        templates: [],
        height: 650,
        ...options  
      }
      var isloadScript = await this.loadScriptAsync(options.url);
      if(isloadScript) {
        var selectorE = document.querySelector(selector);
        if(!selectorE) return null;

        var tid =selectorE.dataset['id'] || btoa(selector);
        if(typeof teditorTiny == 'undefined') window.teditorTiny = {};
        if(teditorTiny[tid]) {     
          teditorTiny[tid].destroy();
        }
        if (typeof tinymce == 'undefined') {           
            return null;
          };
        return new Promise((resolve) => {
          
          tinymce.init({
            selector: selector,
            menubar: true,
            plugins: [
              'advlist autolink lists link image charmap print preview template',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste imagetools wordcount'
            ],
            toolbar: 'fullscreen | bold italic underline strikethrough |template | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | insertfile image media pageembed link pagebreak',
            content_style: options.content_style,
            content_css: options.content_css,
            link_list: options.link_list,
            //image_list: options.image_list,
            image_class_list: options.image_class_list,
            importcss_append: true,
            file_picker_callback: (typeof options.file_picker_callback == 'function'? options.file_picker_callback: null),
            templates: options.templates,      
            image_caption: true,
            height: options.height,
            verify_html: false,
            remove_trailing_brs: false,
            valid_elements:"*[*]",
            valid_children : "+body[style],+body[script], +body[svg], +body[i],+body[*]",
            setup: (editor) => {
              console.log(1111, editor);
              editor.on('init', () => {
                teditorTiny[tid] = editor;
                resolve(editor);
              });
            }

          });
        });

      } else {
        return null;
      }
    }catch(err) {
      console.log(err.message);
      return null;
    }

  }
 
}();
cf.b = new class {
  constructor(){
    this.baseUrl = cache.get('apiHost')? cache.get('apiHost') : ''; 
  }
  async base(url, data) {
    url = this.baseUrl + '/admin' + url;
    var kqRs = await cf.l.postData( url, data);
    if(kqRs[0] == 203) {
      location.reload();
      return null;
    }
    return kqRs;
  }
  async upload_files(files, data = {}) {
    var fmdata = new FormData();
    //var files = evt.target.files;
    for (let i = 0; i < files.length; i++) {
      fmdata.append("files", files[i]);
    }
    Object.keys(data).forEach(fe => {
      if(fe != 'files'){
        fmdata.append(fe, data[fe]);
      }
     
    })  
   
    var response = await fetch(this.baseUrl + "/admin/upload-file-folder", {
      method: "POST",
      body: fmdata,
    });
    return await response.json();
  }
  async delete_file(data) {
    return this.base("/delete-file", data);  
} 
  
 async openImage(path, callback){
    var idMd = 'modal-hinh-anh';
    var idMd$ = '#' + idMd + ' ';
    var tp = `
    <div class="container"><div class="list-images">   </div></div>    
    `;
    var modal = cf.l.formModal(idMd, 'Hình ảnh',
                               `<span class="material-icons" data-action="upload"> upload </span>`, tp ,
                               2, {zIndex: 1390, maxWidth:'80%'});
    var upload_e = document.querySelector(idMd$ + 'span[data-action="upload"]');
    var list_e = document.querySelector(idMd$ + 'div.list-images');
    var tpItem = '\n  <div class="content-img">   \n    <img style="display: ${[\'.jpg\', \'.png\', \'.svg\', \'.webp\', \'.gif\', \'.ico\'].includes(data.extname)? \'block\': \'none\'}" src="${data.url}" title="${data.name}" alt="${data.url}" data-file="${data.url}">\n   \t<span> ${data.name}</span>\n    <div class="overlay">\n      <button class="chon" data-url="${data.url[0] == \'/\'? data.url: (\'/\'+data.url)}" data-name="${data.name}" data-extname="${data.extname}" data-path="${data.path}" data-action="chon"> Chọn </button>\n      <button class="xoa" data-file="${data.url}" data-name="${data.name}" data-extname="${data.extname}" data-path="${data.path}" data-action="xoa"> Xóa </button>\n    </div>\n  </div>\n'
    
    var loadImages = async () => {
      var dataImages = [];
      var dataR = await this.base('/list-file', {path: path});
      if(dataR[0] == 200) {
        dataImages = dataR[1].filter(f => f.type =='file');
      }
      dataImages = dataImages.sort((a,b) => {
        try{
          var aa = new Date(a.time);
          var bb = new Date(b.time);
          if(aa.getTime() > bb.getTime()) {return -1;} else {
            return 1;
          }
        } catch(err) {
          return 0;
        }});
      list_e.innerHTML = dataImages.map(m => tpItem.str2tp({data:m})).join('');
      document.querySelectorAll(idMd$ + 'button[data-action="chon"]').forEach(fe => {
        fe.onclick = () => {
          callback(fe.dataset);
        modal.hide();
        }
      });
      document.querySelectorAll(idMd$ + 'button[data-action="xoa"]').forEach(fe => {
        fe.onclick = () => {
          cf.l.questionAlert('Có chắc chắn xóa không?', async (d) => {
            if(d) {
              var {file} = fe.dataset;
              var dataR = await cf.b.delete_file({path:file });
              if(dataR[0] == 200) {
                fe.closest('div.content-img').remove();
              } else {
                cf.l.alert.error(dataR[1]);
              } 
              
            }
          })
       
        }
      });
    }
    upload_e.onclick = async() => {
     var dsFile = await cf.l.uploadTag();
      var fileRs = await this.upload_files(dsFile, {path: path});
      if(fileRs[0] == 200) {
        loadImages();
      }
      
    }
    await loadImages();
  } 
 
  async myApp() {
    return this.base('/my-app');
  }
  async appUpdate(data){
    return this.base('/app-update', data);
  }
  async docsFind(data) {
    return this.base('/docs-find', data);
  }
  async docsFindOne(data) {
    return this.base('/docs-find-one', data);
  }
  async docsInsert( data) {
    return this.base('/docs-insert',data);
  }
   async docsCopy( data) {
    return this.base('/docs-copy',data);
  }
   async docsUpdate(data) {
    return this.base('/docs-update', data);
  }
  async docsDelete(data) {
    return this.base('/docs-delete', data);
  }
  async doiMatKhau(data) {
    return this.base('/doi-mat-khau', data);
  }
  
}();

